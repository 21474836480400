export const MenuItems = [
    {
      title: 'HeadWear',
      path: '/headwear',
      cName: 'dropdown-link'
    },
    {
      title: 'Dresses',
      path: '/dresses',
      cName: 'dropdown-link'
    },
    {
      title: 'Bags',
      path: '/bags',
      cName: 'dropdown-link'
    },
    {
      title: 'Pants',
      path: '/pants',
      cName: 'dropdown-link'
    },
    {
      title: 'FootWear',
      path: '/footwear',
      cName: 'dropdown-link'
    },

    
  ];